@use "@angular/material" as mat;
@use "mytrilo-admin-theme.scss" as mytrilo-theme;
@use "fonts" as fonts;
@include mat.core();
@include mat.all-component-themes(mytrilo-theme.$mytrilo-theme);
@import "ngx-toastr/toastr";
@import "table";
@import "flex";

html,
body {
  margin: 0;
  height: 100%;
}

@include mat.all-component-densities(-1);
@include mat.input-density(-3);
@include mat.form-field-density(-4);

.note-modal-backdrop {
  z-index: 10 !important;
}

text.highcharts-credits {
  display: none;
}


::-webkit-scrollbar {
  width: 8px;
  overflow-y: scroll;
  background: transparent;
  animation: slide-up 1s;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.mat-mdc-radio-checked {
  .mdc-radio__inner-circle, .mdc-radio__outer-circle {
    border-color: #305dff !important;
  }
}

.steps-container{
  display: flex !important;
}

.onboarding-menu.mat-mdc-menu-panel{
  max-width: fit-content;
}
