$gap: 16px;

.flex-item-basic {
  flex: 1 1 0% !important;
  box-sizing: border-box !important;
}

.flex-item-auto {
  flex: 1 1 auto;
  box-sizing: border-box !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  box-sizing: border-box !important;
}

.flex-row-space-between {
  @extend .flex-row;

  place-content: center space-between;
}

.flex-row-end {
  @extend .flex-row;

  place-content: stretch flex-end;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
}

.flex-center-center {
  display: flex;
  place-content: center;
  align-items: center;
}

.flex-layout-basic {
  flex-direction: row;
  box-sizing: border-box !important;
  display: flex !important;
}

.flex-layout-stretch-flex-start {
  @extend .flex-layout-basic;

  place-content: stretch flex-start;
  align-items: stretch;
}

.flex-layout-stretch-center {
  @extend .flex-layout-basic;

  place-content: stretch center;
  align-items: stretch;
}

// media hide ---
@media screen and (max-width: 599px) {
  .hide-lt-sm {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .hide-xs {
    display: none !important;
  }
}

@media screen and (max-width: 959px) {
  .hide-lt-md {
    display: none !important;
  }
}

@media screen and (max-width: 1279px) {
  .hide-lt-lg {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .hide-gt-xs {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .hide-gt-sm {
    display: none !important;
  }
}

// ----------------------


// media column ----

@media screen and (max-width: 959px) {
  .column-lt-md {
    display: flex !important;
    flex-direction: column !important;
    box-sizing: border-box !important;
  }
}

@media screen and (max-width: 1279px) {
  .column-lt-lg {
    display: flex !important;
    flex-direction: column !important;
    box-sizing: border-box !important;
  }
}

//------------------------