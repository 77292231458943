.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse
}

.table thead th {
  height: 40px;
  color: #ababab;
  border: none;
  font-family: Open Sans, sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .12px;
  line-height: 17px;
  padding: 20px 12px 0px;
  text-align: left;
  -webkit-user-select: none;
  user-select: none
}

.table tbody td {
  height: 40px;
  color: #4e4e57;
  font-family: Open Sans, sans-serif !important;
  font-size: 14px;
  line-height: 19px;
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table tbody td ul {
  margin-bottom: 0
}

.expanded-item-row td {
  border-bottom-width: 0 !important;
}

.expanded-detail-row {
  height: 0;
  min-height: auto;
  display: inline-block;
  width: 100%;
}

.expanded-detail {
  overflow: hidden;
  display: flex;
  padding: 2px;
  background-color: #F8F8F8;
}